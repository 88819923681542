import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppUpdateRequiredComponent } from './shared/app-update-required/app-update-required.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [

  { path: '404', component: NotFoundComponent },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'update-required',
    component: AppUpdateRequiredComponent
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'm',
    loadChildren: () => import('./members/members.module').then(m => m.MembersModule)
  },
  {
    path: 'coordinator',
    loadChildren: () => import('./coordinator/coordinator.module').then(m => m.CoordinatorModule), canActivate: [AuthGuardService]
  },
  {
    path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuardService]
  },
  {
    path: 'share',
    loadChildren: () => import('./coordinator/coordinator.module').then(m => m.CoordinatorModule)
  },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', enableViewTransitions: true })

  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

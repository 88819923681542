import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import {  LuxonDateAdapter, MAT_LUXON_DATE_ADAPTER_OPTIONS, MAT_LUXON_DATE_FORMATS, MatLuxonDateModule, provideLuxonDateAdapter } from '@angular/material-luxon-adapter';
import {  DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { Settings } from 'luxon';
import { JamaatConfig } from 'src/environments/jamaatconfig';
import { AppMaterialModule } from './app-material.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GenericErrorDialogComponent } from './generic-error-dialog/generic-error-dialog.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AppUpdateService } from './services/app-update.service';
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { CustomRouteReuseStategy } from './services/route-reuse-strategy.service';
import { AppUpdateRequiredComponent } from './shared/app-update-required/app-update-required.component';
import { MobileAppUpgradeDialogModule } from './shared/mobileapp-upgrade/mobileapp-upgrade.module';
import { UIUtilityComponentsModule } from './shared/ui.utilitiy.components.module';
import { DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';
import { DateTime } from 'luxon';

export function appInitializerFactory(jamaatConfig: JamaatConfig) {
  return () => jamaatConfig.loadAppConfig()
    .then(async () => {
      const timezone = jamaatConfig.currentConfig()?.timezone;
      if (timezone)
        Settings.defaultZone = timezone;
    });
}

@NgModule({ declarations: [
        AppComponent,
        GenericErrorDialogComponent,
        NotFoundComponent,
        AppUpdateRequiredComponent
        // AppShellComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        AppMaterialModule,
        MobileAppUpgradeDialogModule,
        UIUtilityComponentsModule,
        MatLuxonDateModule], providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [JamaatConfig], // Specify JamaatConfig as a dependency
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true
        },
        {
            provide: RouteReuseStrategy,
            useClass: CustomRouteReuseStategy
        },
        {
            provide: MAT_DATE_LOCALE,
            useFactory: (jamaatConfig: JamaatConfig) => () => jamaatConfig.currentConfig().dateLocale
        },
        {
            provide: DATE_PIPE_DEFAULT_OPTIONS,
            useFactory: (jamaatConfig: JamaatConfig) => {
                // Get the timezone from the configuration
                const timezoneName = jamaatConfig.currentConfig().timezone;
                // Create a DateTime object with the specified timezone
                const nowInTimezone = DateTime.now().setZone(timezoneName);
                // Get the offset in the required format, e.g., "+10:00"
                const timezoneOffset = nowInTimezone.toFormat('Z');
                return { timezone: timezoneOffset };
            },
            deps: [JamaatConfig] // Specify dependencies here
        },
        {
            provide: DateAdapter,
            useClass: LuxonDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS]
        },
        { provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS },
        AppUpdateService,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule { }
